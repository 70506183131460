#login {
  height: 48px;
  float: right;
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-right: 16px;
}
  
 #login ul {
    line-height: 1em;
    padding-left: 24px;
    margin-bottom: 0;
  }


   #login  ul li {
    display: inline-block;
    margin-right: 16px;
    color: #FEBF0F;
  }

    #login li a,
     #login li a:visited,
      #login li a:active{
      color: #FEBF0F;
      text-decoration: none;
    }

